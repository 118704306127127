import React, { Component } from "react";
import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';


class ServiceSlider extends Component {
    // state = {
    //     width: '100'
    // }
    updateDimensions = () => {
        var screenWidth = window.innerWidth
        var stopIndex = 0
        var perpage = this.props.slidePerPage
        if (screenWidth >= 1366) {
            stopIndex = perpage
            // this.setState({ width: this.state.width / perpage })
        } else if (screenWidth <= 1365 && screenWidth >= 1100) {
            stopIndex = perpage - 1
        } else if (screenWidth <= 1099 && screenWidth >= 920) {
            stopIndex = perpage - 2
        } else {
            // this.setState({ width: this.state.width / 2.5 })
            stopIndex = -1
        }
        var i = 0;
        this.flkty.on("select", () => {
            if (document.querySelector('.service-carousel') !== null) {
                var preBtn = document.querySelector('.service-carousel .previous');
                var nextBtn = document.querySelector('.service-carousel .next');
                var slideNumber = this.flkty.selectedIndex + 1;
                var slideStop = this.flkty.slides.length - stopIndex
                if (slideNumber === 1) {
                    i = 0;
                } else {
                    i++;
                }
                if (slideNumber > slideStop) {
                    nextBtn.disabled = true;
                    preBtn.disabled = false;
                    nextBtn.classList.add('pre-next-btn-disabled');
                    preBtn.classList.remove('pre-next-btn-disabled');
                    preBtn.classList.remove('s-prebtn-pos');
                    nextBtn.classList.remove('s-nextbtn-pos');
                } else if (slideStop === 0 || slideStop === -1) {
                    preBtn.disabled = false;
                    nextBtn.disabled = false;
                    nextBtn.classList.remove('pre-next-btn-disabled');
                    preBtn.classList.remove('pre-next-btn-disabled');
                } else {
                    if (i > slideStop) {
                        nextBtn.disabled = true;
                        preBtn.disabled = false;
                        nextBtn.classList.add('pre-next-btn-disabled');
                        preBtn.classList.remove('pre-next-btn-disabled');
                    } else {
                        nextBtn.disabled = false;
                        preBtn.disabled = true;
                        nextBtn.classList.remove('pre-next-btn-disabled');
                        preBtn.classList.add('pre-next-btn-disabled');
                    }
                    preBtn.classList.add('s-prebtn-pos');
                    nextBtn.classList.add('s-nextbtn-pos');
                }
            }
        });
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {

        const flickityOptions = {
            prevNextButtons: true,
            pageDots: false,
            cellAlign: 'left',
            contain: true,
            imagesLoaded: true,
            percentPosition: false,
        }

        const processListing = this.props.processList
        const carouselName = this.props.carouselName

        return (
            <div className="s-slider s-inviewport">
                <div className={`s-slider-main ${carouselName === 'graphic-design' ? 'fusion-carousel-graphic' : carouselName === 'psd-html-service' ? 'fusion-carousel-psd-html-service' : carouselName === 'digital-market' ? 'fusion-carousel-digital' : 's-slider__container'}`}>
                    <div className="shadows shadow-left"></div>
                    <div className="shadows shadow-right"></div>
                    <Flickity
                        flickityRef={c => this.flkty = c}
                        className={'service-carousel'}
                        elementType={'div'}
                        options={flickityOptions}
                    >
                        {processListing && processListing.map((processFlow, index) => (
                            <div key={index} className="service-slider-cell">
                                <div className="" >
                                    <div className="pb-5 2md:pb-6 lg:pb-6-4 w-42">
                                        <img
                                            src={processFlow.process.image.publicURL}
                                            className="w-42 h-42"
                                            alt={processFlow.process.alt}
                                        ></img>
                                    </div>
                                    <div className={`border-t-2 relative border-cyan ${index === 0 ? "ml-4-1  d-work-dot" : "d-work-dots"} mb-6-3 lg:mb-38`}></div>
                                    <div className="ml-4-1">
                                        <h4 className="h-50 md:h-70 pb-2-2 lg:pr-12 pb-3-2 text-h6 lg:text-h4 font-poppings-bold text-term-primary">{processFlow.process.name}</h4>
                                        <p className="pr-5 lg:pr-4 text-p4 lg:text-p2 font-worksans-normal text-term-primary text-opacity-60 opacity-60">{processFlow.process.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Flickity>
                </div>
            </div>
        )
    }
}
export default ServiceSlider;